<template>
  <div class="text-center">
    <Loader :loading="loading" />
    <div class="title">Reporte de eventos</div>
    <div class="mx-12 my-6">
      <v-card>
        <v-row class="px-4">
          <v-col cols="3">
            <Date-Picker v-model="forms.search.fecha_inicio" label="Fecha" offYear hideDetails />
          </v-col>
          <v-col cols="3">
            <Date-Picker v-model="forms.search.fecha_fin" label="Fecha" offYear hideDetails />
          </v-col>
          <v-col cols="5">
            <v-autocomplete v-model="forms.search.usuario" :items="users" item-text="nombreCompleto" item-value="idusuario" label="Empleado" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" class="up" :disabled="!forms.search.fecha_inicio && !forms.search.fecha_fin" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="1">
            <Basic-Btn text="Buscar" color="primary" icon="mdi-magnify" @click="refresh()" :loading="loading" :disabled="!forms.search.usuario" />
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div id="app" style="height: 100vh; margin-top: 10px">
      <vue-pdf-app :pdf="items"></vue-pdf-app>
    </div>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </div>
</template>

<script>
import services from "@/utils/services";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import moment from "moment";
import { BasicBtn, DatePicker, Loader, BasicAlert } from "@/components";

export default {
  name: "docs",
  components: {
    VuePdfApp,
    DatePicker,
    BasicBtn,
    Loader,
    BasicAlert,
  },
  data: () => ({
    loading: false,
    items: null,
    users: [],
    forms: {
      search: {
        usuario: "",
        fecha_inicio: "",
        fecha_fin: "",
      },
    },
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
  }),
  mounted() {
    this.forms.search.fecha_inicio = moment().startOf("month").format("YYYY-MM-DD");
    this.forms.search.fecha_fin = moment().endOf("month").format("YYYY-MM-DD");
    this.getUser();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios({
        method: "get",
        url: services.routes.event + "/report",
        responseType: "blob",
        params: { usuario: this.forms.search.usuario, fecha_inicio: this.forms.search.fecha_inicio, fecha_fin: this.forms.search.fecha_fin },
      })
        .then((response) => {
          var src = URL.createObjectURL(response.data);
          this.items = src;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Documento obtenido exitosamente.";
        });
    },
    async getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombreCompleto =
              (this.users[i].detalles_usuarios[0].no_empleado ? this.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") +
              (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "");
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.up {
  z-index: 9999999999 !important;
}
</style>
